import axios from "./ApiEnterpriseInit";
import mdbAxios from "./ApiInit";
import config from "../../../config";
import { authenticateApi } from "./ApiAuth";
if(!mdbAxios.defaults.headers.common['Authorization'])
    mdbAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
const authenticateUser = ({ email, password }) => {
    return axios.post(config.apiEnterprise.urlConfig.authenticateUser, {
        api_auth_username: email,
        api_auth_password: password,
        auth_application_id: config.apiEnterprise.applicationId,
        auth_application_key: config.apiEnterprise.applicationKey
    });
};
const getUserAccess = (userid) => {
    return axios.get(config.apiEnterprise.urlConfig.userAccess+"/"+userid+"/"+config.apiEnterprise.applicationId,  { headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem("appToken")}`
        }
    });
};
const authenticateUserEmpCode = ({ empcode }) => {
    return axios.post(config.apiEnterprise.urlConfig.authenticateUserEmpCode, {
        api_auth_emp_code: empcode,
        auth_application_id: config.apiEnterprise.applicationId,
        auth_application_key: config.apiConfig.applicationKey
    })
};
const UserApi = async (callback, param = {}) => {
    try {
        if (Object.keys(param).length) {
            const callbackResult = await callback(param);
            return callbackResult;
        } else {
            const callbackResult = await callback();
            return callbackResult;
        }
    } catch (e) {
        if (e.response.status === 401) {
            await authenticateApi();
            if (Object.keys(param).length) {
                const callbackResult = await callback(param);
                return callbackResult;
            } else {
                const callbackResult = await callback();
                return callbackResult;
            }
        }
    }
}
const authenticateApp = async (creds) => {
   await axios.post(config.apiEnterprise.urlConfig.authenticateApp, {}, {  auth: {
        username: config.apiEnterprise.applicationId,
        password: config.apiEnterprise.applicationPassword
    }}).then(token => {
        localStorage.setItem("appToken", token.data);
        if(creds.email)
            return authenticateUser(creds);
        else
            return authenticateUserEmpCode(creds)
    }).then( async data => {
        localStorage.setItem("token", data.data.token);
        var module_access = [];
        const user_access = await getUserAccess(btoa(data.data.user_email));
        user_access.data.map(function (object) {
            module_access.push({"module_name": object["module_name"],"parent_id": object["parent_module_id"]});
        });
        localStorage.setItem("user_id", data.data.user_id);
        localStorage.setItem("user_name", data.data.user_name);
        localStorage.setItem("user_full_name", data.data.user_full_name);
        localStorage.setItem("module_access", JSON.stringify(module_access));
        localStorage.setItem("user_pnl", data.data.user_pnl);
    });
};
const getCommonFilterDDOptions = () => {
    return axios.get(config.apiEnterprise.urlConfig.masterDataList,  { headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem("appToken")}`
        }
    });
};
const getFilterDDOptions = (batch) => {
    return mdbAxios.get(`${config.apiConfig.tempApiUrl}${config.apiConfig.urlConfig.defaultFilterOptions}?batch=${batch}`);
};
const getLevelList = () => {
    return mdbAxios.post(`${config.apiConfig.tempApiUrl}${config.apiConfig.urlConfig.levelList}`);
}
const getFunctionList = () => {
    return mdbAxios.post(`${config.apiConfig.tempApiUrl}${config.apiConfig.urlConfig.functionList}`);
}

//preference-settings mukesh 
const getpreferences=()=>{
        return axios.get(`${config.apiEnterprise.urlConfig.preferences}?fields={"_id" : 1, "website_industry_id" : 1, "website_industry_name" : 1,"sgf" : 1}`,  { headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem("appToken")}`
        }
    });
}

export {
    authenticateUser,
    authenticateUserEmpCode,
    UserApi,
    getUserAccess,
    authenticateApp,
    getCommonFilterDDOptions,
    getFilterDDOptions,
    getLevelList,
    getFunctionList,
    getpreferences,
};