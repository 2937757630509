const pnlCodes = {
    ...["Cognition", "info"].reduce((acc, key) => ({ ...acc, [key]: 552 }), {}),
    ...["FutureBridge", "rna"].reduce((acc, key) => ({ ...acc, [key]: 2 }), {}),
    ...["WhatNext", "ipl"].reduce((acc, key) => ({ ...acc, [key]: 3 }), {})
}
const config = {
    apiConfig: {
        apiUrl: process.env.REACT_APP_MDB_API_BASE_URL,
        tempApiUrl: process.env.REACT_APP_TEMP_MDB_API_BASE_URL,
        refineListUrl: process.env.REACT_APP_REFINE_LIST_API_URL,
        apiAuth: {
            api_auth_username: '',
            api_auth_password: ''
        },
        applicationKey: "abcdef",
        urlConfig: {
            userInfo: '/user/find',
            myList: '/list/my-list',
            myListDetail: '/list/list-detail',
            createList: '/list/create-list',
            updateList: '/list/update-list',
            contactList: '/contact/list',
            contactListSearch: '/contact/listwithsearch',
            contactDetails: '/contact/details',
            contactCount: '/count/contact-count',
            companyCount: '/count/company-count',
            searchFilter: '/search/filter',
            contactWebsite: '/report/contact_website_report',
            contactNewsletter: '/report/contact_newsletter_report',
            contactEmail: '/report/contact_email_report',
            contactProductDetail: '/report/contact_product_details',
            contactProductReport: '/report/contact_product_report',
            contactPageHit: '/mautic/page-hit',
            contactEmailStat: '/mautic/email-stat',
            filterMenu: '/search/filter-list',
            filteredContactCount: '/search/contact-count',
            defaultFilterOptions: '/search/default-filter-options',
            companyList: '/company/list',
            companyListAutocomplete: '/company/listautocomplete',
            groupListAutocomplete: '/company/grouplistautocomplete',
            blockCount: '/count/block-count',
            getClientUser: 'customers/{customerId}',
            getAllSubscriptionHistory: 'subscriptions/history',
            getDownloadCount: '/contact/downloadcount',
            createClientUser: 'customers',
            ///Unsubscription
            unsubList: '/unsubscription/list',
            unsubscribeContact: '/unsubscription/contact',
            createUnsubList: '/unsubscription/createlist',
            deleteUnsubList: '/unsubscription/deletelist',
            publishUnsubList: '/unsubscription/publishlist',
            viewUnsubList: '/unsubscription/viewlist',
            ///Suppression
            suppressionList: '/suppression/list',
            suppressContact: '/suppression/contact',
            createSuppressionList: '/suppression/createlist',
            deleteSuppressionList: '/suppression/deletelist',
            publishSuppressionList: '/suppression/publishlist',
            viewSuppressionList: '/suppression/viewlist',
            /// Addressable Market
            AddressableMarket: '/addressable/addressable-market',
            AddressableGroupDetails: '/addressable/group-details',
            downloadList: '/list/download-list',
            enrichedDataCount: '/list/enriched-count',
            contactsbylist: '/list/list-contacts',
            AddressableMarketList: '/addressable/addressable-market-list',
            MarketDetails: '/addressable/addressable-market-details',
            AddressableDetails: '/addressable/addressable-details',
            addMarket: '/addressable/addmarket',
            totalAdmCount: '/addressable/total_adm_count',
            updateMarket: '/addressable/updatemarket',
            refreshMarket: '/addressable/refresh_market',
            getADMData: '/addressable/getadmdrilldowndata',
            getGroupList: '/addressable/getGroupList',
            getGroupData: '/addressable/getGroupData',
            getContactData: '/addressable/getContactData',
            ///Contact CRUD
            addContact: '/contact/add',
            addCompany: '/contact/add-company',
            editContact: '/contact/edit',
            deleteContact: '/contact/delete',
            //Country List
            cityList: '/city/list',
            //Country List
            countryList: '/country/list',
            //Industry List
            industryList: '/industry/list',
            //Region List
            regionList: '/region/list',
            //Function List
            functionList: '/function/list',
            //Level List
            levelList: '/level/list',
            // Primary Sector List
            primarySector: '/sector/primary-list',
            primarySectors: '/sector/primarySectors',
            // Sub Sector List
            subSector: '/sector/sub-list',
            subSectorWithPrimarySector: '/sector/subsector_with_primarysector',
            // Business Area
            businessArea:'/businessarea/list',
            //Sanitization
            sanitizeFirstName: '/sanitization/firstname',
            sanitizeLastName: '/sanitization/lastname',
            //Allocation
            bdSalesUserList: '/bdsystem/getsalesusers',
            allocateContacts: '/bdsystem/allocatecontacts',
            //Campaign Management
            campaignList: '/campaign/list',
            createCampaign: '/campaign/create',
            viewCampaignLeads: '/campaign/contact-list',
            runCampaign: '/campaign/run',
            campaignDetails: '/campaign/details',
            mauticSegments: '/campaign/mautic_segments',
            mauticInstance: '/campaign/mautic_instance',
            pushToMautic: '/campaign/push_to_mautic',
            updateCampaignContact: '/campaign/updatecontact',
            deleteCampaignContact: '/campaign/deletecontact',
            campaignSlotList: '/campaign/slot/list',
            createCampaignSlot: '/campaign/slot',
            addExistingCampaign: '/campaign/addexisting',
            downloadCampaignContacts: '/campaign/downloadcontacts',

            //Practice Team list
            getPracticeTeam: '/practiceteam/practiceTeamList', // db collection is : marketingdb_practice_team
            unsubscribeReport: '/report',
            unsubscribeSummary: '/report/unsubscribedSummery',
            unsubscribeReports: '/report/unsubscribedReport',
            dncList: '/dncList',
            dncSuppress: '/dncList/unsuppress',
            addCampaignPracticeTeam: '/addCampaign/getPracticeTeam', // db collection is : marketingdb_campaign_master
            getCampaignList: '/addCampaign/getCampaigns',
            setCampaignList: '/addCampaign/setCampaigns',
            getList: '/addCampaign/getList',
            deleteCampaign: '/addCampaign/deleteCampaign',
            BDSystemUpdateCampaign: 'http://203.200.229.72/DemoServices/WebExSer.asmx',
            getLevel: '/addContact/getLevel',
            getFunction: '/addContact/getFunction',
            getActivities: '/addCampaign/getActivities',
            getSavedSearch: '/savesearch/getSavedSearches',
            saveThisSearch: '/savesearch/saveThisSearch',
            deleteSavedSearch: '/savesearch/deleteSavedSearch',
            editSavedSearchName: '/savesearch/editSavedSearch',
            checkIfSearchNameExists: '/saveSearch/checkIfSearchNameExists',
            getDistinctCompanies:'/saveSearch/getDistinctCompanies',
            getDistinctGroups:'/saveSearch/getDistinctGroups',
            updateSavedSearchCount:'/saveSearch/updateSavedSearchCount',
            findCampaign:'/campaign/findCampaigns',

            // Toll gate ROutes List
            findTollGateCompany:'/tollGate/searchRecord',
            tollgateAdvanceSearch:'/tollGate/advanceSearch',
            tollgateAddCompany:'/tollGate/operation/add_company',
            tollgateEditCompany:'/tollGate/operation/edit',
            tollgateTagCompany:'/tollGate/operation/tagGroup',
            tollgateBatchList:'/tollGate/getBatch',
            tollgateGetCompany:'/tollGate/getCompany',
            tollgateCompanyRecords:'/tollGate/getTollgateCompany',
            tollgateMerge:'/tollGate/operation/merge',
            tollgateGetAllCompanies:'/getAllTollgateCompany',

            //Solr
            solrAPI: '/solar_api'

        }
    },
    apiEnterprise: {
        apiUrl: process.env.REACT_APP_ENTERPRISE_API_BASE_URL,
        apiAuth: {
            api_auth_username: '',
            api_auth_password: ''
        },
        applicationId: process.env.REACT_APP_ENTERPRISE_MDB_APP_ID,
        applicationKey: process.env.REACT_APP_ENTERPRISE_MDB_APP_KEY,
        applicationPassword: process.env.REACT_APP_ENTERPRISE_MDB_APP_PASSWORD,
        urlConfig: {
            authenticateUserEmpCode: '/enterprise/v1/auth/authenticatebyempcode',
            authenticateUser: '/enterprise/v1/auth/authenticate',
            userAccess: '/enterprise/v1/access/getaccess',
            authenticateApp: '/authentication',
            masterDataList: '/enterprise/v1/master-data-lists',
            preferences: '/websiteindustrysgf',
            getLevel: '/contact/v1/getLevel',
            getFunction: '/contact/v1/getFunction'
        }
    },
    apiConnect: {
        apiUrl: process.env.REACT_APP_CONNECT_API_BASE_URL,
        apiAuth: {
            username: process.env.REACT_APP_CONNECT_API_USER_NAME,
            password: process.env.REACT_APP_CONNECT_API_PASSWORD
        },
        urlConfig: {
            // getOrgChart : `/orgchart/${pnlCodes[localStorage.getItem('user_pnl')]}/0/fetch`
            getOrgChart: `/orgchart/${pnlCodes[localStorage.getItem('user_pnl')]}/0/fetch`
        }
    },
    settings: {
        leadListLimit: process.env.REACT_APP_LEAD_LIST_LIMIT,
        accountListLimit: process.env.REACT_APP_ACCOUNT_LIST_LIMIT,
        filterListLimit: process.env.REACT_APP_FILTER_LIST_LIMIT,
        uploadListVersion: process.env.REACT_APP_UPLOAD_LIST_VERSION
    },
    baseUrl: process.env.REACT_APP_MDB_BASE_URL,
    loginThroughPassword: process.env.REACT_APP_LOGIN_THROUGH_PASSWORD //yes/no		 
}
export default config;
