import React from "react";
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
const Navigation = (props) => {
    const {routeNav } = props;
    if (typeof(routeNav.childNav)!="undefined" && routeNav.childNav.length !== 0) {
      return (
        <li className="menu collapse-box">
          <Button onClick={()=>props.handleSideDrawer(false)}>
            <i className={routeNav.parentIcon} />
            <span className="nav-text">
              {routeNav.parent}
            </span>
          </Button>
          <ul className="sub-menu">
            {routeNav.childNav.map((childNav,index)=>(
              <li key={index} onClick={()=>props.handleSideDrawer(true)}>
              <NavLink className="prepend-icon" to={childNav.path}>
                <span className="nav-text">{childNav.child} </span>
              </NavLink>
            </li>
            ))}

          </ul>
        </li>
      );
    } else {
      return (
      <li className="menu no-arrow" onClick={()=>props.handleSideDrawer('close_only')}>
      <NavLink to={routeNav.path}>
        <i className={routeNav.parentIcon}/>
        <span className="nav-text">{routeNav.parent}</span>
      </NavLink>
    </li>)
    }
  }

  export default Navigation;