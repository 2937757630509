import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CustomScrollbars from "../../../utilities/CustomScrollbars";
import { routeNavigation } from "../../helper/route";
import { connect } from "react-redux";
import Navigation from "../../hoc/Navigation";
class SidenavContent extends Component {
  constructor(props) {
    super();
    this.userRoute = routeNavigation;
    const accessed_modules = JSON.parse(localStorage.getItem("module_access"));
    // console.log(accessed_modules);
    // accessed_modules.push({
    //  module_name: 'Operations',
    //  parent_id: 0
    // })
    // console.log(accessed_modules);
    if (accessed_modules !== null && accessed_modules !== '' && typeof(accessed_modules)!=="undefined" && accessed_modules.length>0 ) {
      var accessed_routes = [ {
        parent: "Dashboard",
        parentIcon: 'zmdi zmdi-tv-list zmdi-hc-fw',
        path: '/app/dashboard'
      },
    {
      parent: "Universe",
      parentIcon: 'zmdi  zmdi-collection-text zmdi-hc-fw',
      path: '/app/display-count'
    }
    ];
        accessed_modules.map(function (acc) {
           routeNavigation.map(function (rt) {
             if (acc.parent_id === 0 && acc.module_name === rt.parent) {
               accessed_routes.push(rt);
             } else {
               if (typeof rt.childNav !== "undefined") {
                 var childNav = rt.childNav.filter(function (object) { return object['child'] === acc.module_name });
                 if (childNav.length > 0) {
                   accessed_routes.push({ parent: rt.parent, parentIcon: rt.parentIcon, childNav: childNav });
                 }
               }
             }
           })
         })
      this.userRoute = accessed_routes;
    }
  }

  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path
    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains("open"))) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }
  render() {
    return (
      <CustomScrollbars className="fb-side-nav">
        <ul className="nav-menu">
          {
            this.userRoute.map((routeNav, index) => (
              <Navigation key={index} routeNav={routeNav} handleSideDrawer={this.props.handleSideDrawer}/>
            ))
          }

        </ul>
      </CustomScrollbars>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { route } = auth;
  return { route:route };
}
export default withRouter(connect(mapStateToProps)(SidenavContent)); 