import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_COMMON_FILTER_OPTIONS_SUCCESS, FETCH_FILTER_OPTIONS_SUCCESS, FETCH_FUNCTION_FILTER_OPTIONS_SUCCESS, FETCH_LEVEL_FILTER_OPTIONS_SUCCESS,FETCH_PREFERENCES_FILTER_OPTIONS_SUCCESS,HIDE_MESSAGE, SHOW_MESSAGE} from '../../app/constants/ActionTypes'

const INIT_STATE = {
  error: "",
  loading: false,
  message: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: '',
        loading: false
      };
    }
    case FETCH_COMMON_FILTER_OPTIONS_SUCCESS: {
      
      return {
        ...state,
        group_region: action.payload.data.regions,
        group_country: action.payload.data.countries,
        group_primary_sector: action.payload.data.sectors,
        group_sub_sector: action.payload.data.sub_sectors,
        company_region: action.payload.data.regions,
        company_country: action.payload.data.countries,
        primary_sector: action.payload.data.sectors,
        sub_sector: action.payload.data.sub_sectors,
        contact_region: action.payload.data.regions,
        contact_country: action.payload.data.countries,
        company_primary_sector: action.payload.data.sectors,
        company_sub_sector: action.payload.data.sub_sectors,
        prospecting:["Yes","No"],
        email_sent:["Yes","No"],
        contact_industry: state.contact_industry || action.payload.data.contact_industry,
        
      };
    }
    case FETCH_FILTER_OPTIONS_SUCCESS: {
      
      return {
        ...state,
        met_notmet: state.met_notmet || action.payload.data.met_notmet,
        not_to_meet: state.not_to_meet || action.payload.data.not_to_meet,
        must_have: state.must_have || action.payload.data.must_have,
        company_size: state.company_size || action.payload.data.company_emp_size,
        company_specialization: state.company_specialization || action.payload.data.is_cheers_registered_vendor,
        relationship_status: state.relationship_status || action.payload.data.relationship_status,
        overall_impact: state.overall_impact || action.payload.data.overall_impact,
        referee: state.referee || action.payload.data.referee,
        permission_to_use_logo: state.permission_to_use_logo || action.payload.data.permission_to_use_logo,
        willingness_for_being_reference: state.willingness_for_being_reference || action.payload.data.willingness_for_being_reference,
        visit_to_our_office: state.visit_to_our_office || action.payload.data.visit_to_our_office,
        fb_event_webinar: state.fb_event_webinar || action.payload.data.fb_event_webinar,
        download_prespective: state.download_prespective || action.payload.data.download_prespective,
        register_subscriber_of_my_futurebridge: state.register_subscriber_of_my_futurebridge || action.payload.data.register_subscriber_of_my_futurebridge,
        business_given: state.business_given || action.payload.data.business_given,
        company_type: state.company_type || action.payload.data.company_type,
        testimonial_letter: state.testimonial_letter || action.payload.data.testimonial_letter,
        brand_name: state.brand_name || action.payload.data.brand_name ,
        bounce_status: state.bounce_status || action.payload.data.bounce_status,
        email_status: state.email_status || action.payload.data.email_status,
        contact_industry: state.contact_industry || action.payload.data.contact_industry,
        company_ln_industry:state.contact_industry || action.payload.data.contact_industry,
       
      };
    }
    case FETCH_FUNCTION_FILTER_OPTIONS_SUCCESS: {
      return {
        ...state,
        function: action.payload.data
      };
    }
    case FETCH_LEVEL_FILTER_OPTIONS_SUCCESS: {
      return {
        ...state,
        level: action.payload.data
      };
    }
    case FETCH_PREFERENCES_FILTER_OPTIONS_SUCCESS: {
      return {
        ...state,
        industry: state.industry || action.payload.data.data,
        sgf: state.sgf || action.payload.data.data,
      };
    }
  
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    default:
      return state;
  }
}
