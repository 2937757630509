import axios from './ApiInit'
import config from "../../../config";

const authenticateApi = async () => {
    try {
        const authResult = await axios.post(config.apiEnterprise.urlConfig.authenticateUser, {
            api_auth_username: config.apiEnterprise.apiAuth.api_auth_username,
            api_auth_password: config.apiEnterprise.apiAuth.api_auth_password,
            auth_application_id: config.apiEnterprise.applicationId,
            auth_application_key: config.apiEnterprise.applicationKey
        });
        if (typeof authResult.data.token != 'undefined') {
            axios.defaults.headers.common['Authorization'] = "Bearer " + authResult.data.token;
            return authResult.data.token;
        }
        return '';
    }
    catch (e) {
        console.log("UNAUTHORIZE REQUEST");
    }
};

export { authenticateApi };