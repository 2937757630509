import asyncComponent from '../hoc/asyncComponent';
// import SelectedCompanyData from '../views/TollGate/component/SelectedCompanyData';

const AdvancedSearch = asyncComponent(() => import('../views/AdvancedSearch/Listing'));
const ContactDetails = asyncComponent(() => import('../views/Contact/Details'));
const AddressableMarket = asyncComponent(() => import('../views/AddressableMarket/MarketDetails'));
const MyList = asyncComponent(() => import('../views/MyList/Listing'));
const UnsubList = asyncComponent(() => import('../views/Unsubscribe/Listing'));
const SuppressionList = asyncComponent(() => import('../views/Suppression/Listing'));
const NotFound = asyncComponent(() => import('../components/Error404/index'));
const AddressableMarketFNN = asyncComponent(() => import('../views/AddressableMarket/DetailsFNN'));
const AddressableMarketMobility = asyncComponent(() => import('../views/AddressableMarket/DetailsMobility'));
const AddressableMarketEnergy = asyncComponent(() => import('../views/AddressableMarket/DetailsEnergy'));
const AddressableMarketList = asyncComponent(() => import('../views/AddressableMarket/List'));
const CampaignList = asyncComponent(() => import('../views/Campaign/List'));
const SlotList = asyncComponent(() => import('../views/Campaign/Slot/Listing'));
const UnsubscribedUserList = asyncComponent(() => import('../views/UnsubscribeManagement/UnsubscribedUserList'))
const DncList = asyncComponent(() => import('../views/DncList/DncList'))
const UnsubscribeReport = asyncComponent(() => import('../views/UnsubscribeReport/UnsubscribeReport'))
const Dashboard = asyncComponent(() => import('../views/Dashboard/index'));
const AddCampaign = asyncComponent(() => import('../views/Campaign/AddCampaign'))
const SaveSearch = asyncComponent(() => import('../views/AdvancedSearch/SaveSearch'))
const Operations = asyncComponent(() => import('../views/Operations/index'))


const TollgateCompanyGrid = asyncComponent(() => import('../views/TollGate/TollgateCompanyGrid'))
const CompanyAction = asyncComponent(() => import('../views/TollGate/CompanyAction'))
const TollgateCompanyBatch = asyncComponent(() => import('../views/TollGate/TollgateBatch'))
const MergeRecords = asyncComponent(() => import('../views/TollGate/MergeCompany'))
const DisplayCount = asyncComponent(() => import('../views/DisplayCount/DisplayCount'));

// const DataMatchList = asyncComponent(() => import('../views/TollGate/listing'));
// const TollgateEditData = asyncComponent(() => import('../views/TollGate/component/tableGrid'));
// const TollgateNewGrid = asyncComponent(() => import('../views/TollGate/NewListing'));

// const DataSet = asyncComponent(() => import('../views/TollGate/component/companyData.js'));

// const TollgateNewData = asyncComponent(() => import('../views/Tollgate/component/newData'));


const allRoute = [
    // { path: '/', exact: true, name: 'Route' },
    { path: '/app/my-list', name: 'My List', component: MyList },
    { path: '/app/saved-searches', name: 'Saved Search', component: SaveSearch },
    { path: '/app/advanced-search/:category?/:keyword?', name: 'My List', component: AdvancedSearch },
    { path: '/app/contact-details/:contactid?', name: 'Contact Details', component: ContactDetails },
    { path: '/app/unsubscribe-list', name: 'Unsubscribe List', component: UnsubList },
    { path: '/app/addressable-market/fnn', name: 'Addressable Market', component: AddressableMarketFNN },
    { path: '/app/addressable-market/mobility', name: 'Addressable Market', component: AddressableMarketMobility },
    { path: '/app/addressable-market/energy', name: 'Addressable Market', component: AddressableMarketEnergy },
    { path: '/app/addressable-market-list', name: 'Addressable Market', component: AddressableMarketList },
    { path: '/app/addressable-market/:market_id?', name: 'Addressable Market', component: AddressableMarket },
    { path: '/app/suppression-list', name: 'Suppression List', component: SuppressionList },
    { path: '/app/dashboard', name: 'Dashboard', component: Dashboard },
    { path: '/app/campaign-list', name: 'Campaigns', component: CampaignList },
    { path: '/app/campaign/slot-list', name: 'Campaigns', component: SlotList },
    { path: '/app/campaign/add-campaign', name: 'Campaigns', component: AddCampaign },
    { path: '/app/reports/unsubscribe-user-report', name: 'Reports', component: UnsubscribedUserList },
    { path: '/app/dnc-list', name: 'Reports', component: DncList },
    { path: '/app/unsubscribe-report', name: 'Reports', component: UnsubscribeReport },
    { path: '/app/saved-searches', exact: true, name: 'Saved Searches', component: SaveSearch },
    { path: '/app/tollgate-batch', exact: true, name: 'Toll gate company data', component: TollgateCompanyBatch },
    { path: '/app/tollgate-list/companys/:id', exact: true, name: 'Toll gate company data', component: TollgateCompanyGrid },
    { path: '/app/tollgate-list/merge-company', exact: true, name: 'Toll gate company data', component: MergeRecords },
    { path: '/app/tollgate-company/action/:id', exact: true, name: 'Toll gate company data', component: CompanyAction },
    { path: '/app/refine-list', exact: true, name: 'Operations', component: Operations },
    { path: '/app/display-count', name: 'Display Count', component: DisplayCount },

    // { path: '/app/tollgate/dataSet', exact: true, name: 'Toll gate company data', component: DataSet },
    { path: '', exact: true, name: 'Error 404', component: NotFound },
];
const routeNavigation = [
    {
        parent: "Display Count",
        parentIcon: 'zmdi  zmdi-collection-text zmdi-hc-fw',
        path: '/app/display-count'
    },
{
        parent: "Dashboard",
        parentIcon: 'zmdi zmdi-tv-list zmdi-hc-fw',
        path: '/app/dashboard'
    },
    {
        parent: 'Advance Search',
        parentIcon: 'zmdi zmdi-zoom-in zmdi-hc-fw',
        childNav: [
            {
                child: "Leads/Account Search",
                path: "/app/advanced-search"
            },
            {
                child: "Saved Search List",
                path: "/app/saved-searches"
            }
        ]
    },
    {
        parent: 'My List',
        parentIcon: 'zmdi zmdi-accounts-list-alt zmdi-hc-fw',
        childNav: [
            {
                child: "Manage List",
                path: "/app/my-list"
            },
            {
                child: "Refine List",
                path: "/app/refine-list"
            },
            {
                child: "Tollgate List",
                path: "/app/tollgate-batch"
            }
        ]
    },
    {
        parent: "Campaigns",
        parentIcon: 'zmdi zmdi-calendar zmdi-hc-fw',
        childNav: [
            {
                child: 'Campaign List',
                path: '/app/campaign-list'
            },
            {
                child: 'Slot Management',
                path: '/app/campaign/slot-list'
            }
        ]
    },
    // TODO : Functionality is yet to be implemented		
    {
        parent: 'Masters',
        parentIcon: 'zmdi zmdi-book zmdi-hc-fw',
        childNav: [

            {
                child: "Unsubscribe List",
                path: "/app/unsubscribe-list"
            }
            ,
            {
                child: "Suppression List",
                path: "/app/suppression-list"
            },
            {
                child: 'Campaign Master',
                path: '/app/campaign/add-campaign'
            }
        ]
    },
    {
        parent: "Addressable Market",
        parentIcon: 'zmdi  zmdi-collection-text zmdi-hc-fw',
        path: '/app/addressable-market-list'
    },
    //MIS REPORT
    {
        parent: 'Reports',
        parentIcon: 'zmdi zmdi-chart zmdi-hc-fw',
        childNav: [
            {
                child: "Unsubscribe Report",
                path: "/app/unsubscribe-report"
            }
        ]
    },
]
export { allRoute, routeNavigation };
