import React from 'react';
import axios from '../../../store/middelware/api/ApiInit';
import { connect } from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { signout_user } from '../../../store/actions/Auth.action'
import IntlMessages from '../../../utilities/IntlMessages';
import config from '../../../config';

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
    userName:'User'
  };

  componentDidMount() {
    var userName = localStorage.getItem('user_name').replace(/['"]+/g, '');
    this.setState({ userName: userName });
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="user-profile d-flex flex-row align-items-center" style={{ float: "right" }}>

        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>Welcome {this.state.userName} <i
            className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
              marginTop:"24px"
            }
          }}
        >
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.profile" />
          </MenuItem>
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.setting" />
          </MenuItem>
          <MenuItem onClick={() => {
            this.handleRequestClose();
            this.props.signout_user()
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale }
};
export default connect(mapStateToProps, { signout_user })(UserInfo);
// export default connect(mapStateToProps, {})(UserInfo);


