import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import axios from '../../store/middelware/api/ApiInit';
import "../../assets/vendors/style";
import defaultTheme from '../../assets/themes/defaultTheme';
import AppLocale from '../../localization';
import DefaultLayout from './DefaultLayout/DefaultLayout';
import Login from '../views/Login/Login';
import { setInitUrl } from '../../store/actions/Auth.action';
import RTL from './DefaultLayout/RTL';
import asyncComponent from '../hoc/asyncComponent';

const RestrictedRoute = ({ component: Component, token, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />}
  />;

class AuthLayout extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + nextProps.token;
    }

  }
  render() {

    const { location, locale, token, initURL, isDirectionRTL } = this.props;
    if (location.pathname === '/') {
      if (token === null) {
        return (<Redirect to={'/login'} />);
      } else if (initURL === '' || initURL === '/' || initURL === '/login') {
        return (
          <Redirect to={`/app/dashboard`} />
        );
      } else {
        return (<Redirect to={initURL} />);
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <Route path='/login' component={Login} />
                  <RestrictedRoute path="/app" token={token}
                    component={DefaultLayout} />
                  <Route
                    component={asyncComponent(() => import('../components/Error404'))} />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, token, initURL } = auth;
  return { sideNavColor, token, locale, isDirectionRTL, authUser, initURL }
};

export default connect(mapStateToProps, { setInitUrl })(AuthLayout);

