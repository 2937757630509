import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars
} from '@fortawesome/free-solid-svg-icons';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from '../../constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from '../../../store/actions/Setting';

class SideNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { variantType: "permanent" };
  }

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  handleSideDrawer = (action) => {
    if (this.state.variantType === "temporary" && action === true) {
      /// open
      this.setState({ variantType: "permanent" });
    } else if (action === 'close_only') {
      this.setState({ variantType: "permanent" });
    } else {
      /// close
      this.setState({ variantType: "temporary" });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
  }

  render() {
    const { navCollapsed, drawerType, width, navigationStyle } = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    return (
      <div className={`app-sidebar d-none ${drawerStyle}`} >
        <Drawer className="fb-mini-drawer"
          variant={this.state.variantType}
          open={true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav',
          }}

        >
          <div className="app-quick-access">
            <FontAwesomeIcon icon={faBars} className="Icon" onClick={() => this.handleSideDrawer(true)} style={{ cursor: "pointer" }} />{/* <h3>MarketingDatabase</h3> */}
          </div>
          <SidenavContent handleSideDrawer={this.handleSideDrawer} />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  return { navCollapsed, drawerType, width, navigationStyle }
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav));

