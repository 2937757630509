import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import configureStore, {history} from './store';
import AuthLayout from './app/layout/AuthLayout';

import { SnackbarProvider } from 'notistack';


export const store = configureStore();

const App = () =>
<SnackbarProvider  anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }} autoHideDuration = {3000}>
  <Provider store={store}>
    <ConnectedRouter history={history}>
	 <BrowserRouter>
      <Switch>
        <Route path="/" component={AuthLayout}/>
      </Switch>
	   </BrowserRouter>
    </ConnectedRouter>
  </Provider>
  </SnackbarProvider>;

export default App;
